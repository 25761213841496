export const CAL_PLUS_FEATURES = {
  customizedScreensaver: {
    heading: 'Photo Screensaver',
    text: `Bring a smile to each day - show beautiful family photos and videos when your Calendar is not in use.`,
  },
  mealPlanning: {
    heading: 'Smart Meal Planning',
    text: `The answer to "what's for dinner?" Plan meals from your own recipe box, or let us magically add new ones based on your diet & tastes.`,
  },
  magicImport: {
    heading: 'Magic Import',
    text: `Easily import events, recipes, and to-dos just by snapping a photo, or forwarding an email or PDF.`,
  },
  rewards: {
    heading: 'Chore Rewards',
    text: `Motivate your kids with stars and rewards for completing chores and any other tasks.`,
  },
}
export const PRODUCT_DROPDOWN_LABELS = {
  27: {
    label: `27" Calendar Max`,
  },
  15: {
    label: `15" Calendar`,
  },
  10: {
    label: `10" Calendar`,
  },
}
